/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const TextWrapper = styled.div`
  height: fit-content;
  width: 94%;
  margin: 30px auto 0 auto;
  font-size: ${props => props.theme.fontSizes.size24};
  ${props =>
    props.customBG &&
    `
    background: ${props.customBG};
    box-shadow: 7px 7px #000000;
    
    font-family: ${props.theme.fontFamily.poppins};
    font-weight: ${props.theme.fontWeight.w800};
    line-height: 1.3;
    padding: 20px 40px;
    color: ${props.theme.color.white};
  `}
  ${props => props.theme.media.lessThan('mobile')`
      font-size: ${props.theme.fontSizes.size16}
  `}
`
const TextLinkWrapper = styled.a`
  color: ${props => props.theme.color.white};
  text-decoration: underline;
  &:hover {
    color: ${props => props.theme.color.white};
  }
`

const BibleSeparateTextSection = ({ customBG, belowAuthors }) => {
  const createText = () => {
    return belowAuthors.map(el => {
      if (el.link) {
        return (
          <TextLinkWrapper href={el.link} key={el.link} target='_blank'>
            {el.text}
          </TextLinkWrapper>
        )
      }
      return el.text
    })
  }

  return <TextWrapper customBG={customBG}>{createText()}</TextWrapper>
}

BibleSeparateTextSection.propTypes = {
  customBG: PropTypes.string,
  belowAuthors: PropTypes.array.isRequired,
}

BibleSeparateTextSection.defaultProps = {
  customBG: '',
}

export default BibleSeparateTextSection
