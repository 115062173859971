import LocalizedStrings from 'react-localization'
import avaliableLangs from './avaliableLangs'

const tiktokStrings = new LocalizedStrings({
  [avaliableLangs.en]: {
    main: {
      title: `The TikTok Bible for Influencers & Creators`, // :[nexLinePoint]`Learn how to go viral on TikTok and monetize your reach`,
      desc:
        '200 pages of step-by-step instructions with tips, tricks, examples and hacks for every creator who wants to win followers and brand deals or create his own products',
    },
    buyButton: {
      text: 'FREE DOWNLOAD', //'Release: March 3rd!', //'BUY NOW FOR 99€*',
      taxes: '', // not needed
    },
    orderPromo: {
      text: 'Order now and get 10% discount on the TikTok bible!',
    },
    freebieButton: {
      text: 'GET YOUR FREE CHAPTER',
    },
    workedWith: 'Leading brands that our authors have worked with',
    // video section is erased for now in tiktok page
    // videoDesc: {
    //   text:
    //     'GET A FIRST IMPRESSION ON THE BIBLE’S CONTENT FOR FREE IN THIS VIDEO',
    //   marked: 'FOR FREE',
    // },
    authorFirst: {
      title: 'About the author',
      name: 'Adil Sbai',
      desc: [
        {
          text:
            "Adil has advised hundreds of Influencers, Brands and Agencies on Branding and Marketing issues in recent years. He is founder and CEO of multiple companies that are focused on Social Media, including OnlinePunks, influData, an Influencer search engine with 7 million data evaluations of Influencers. OnlinePunks and influData's customers include Marketers, Agencies and Brands from across the German-speaking world. In addition to Younes, he advised and supported other Influencers such as RoadToGlory or JulesVogel.",
          links: {
            RoadToGlory: 'https://www.instagram.com/roadtogloryjil/',
            'JulesVogel.': 'https://www.instagram.com/julesvogel/',
          },
        },
      ],
    },
    authorSecond: {
      title: 'About the author',
      name: 'Younes Zarou',
      desc: [
        {
          text:
            'Younes is one of the most creative and fastest growing Influencers in Germany and has grown from 0 to 1 million followers on TikTok within just 5 months. In the past 4 years he has won 40,000 followers on Instagram and has collaborated with numerous well-known brands. For the first time, he shares his secret of success in the TikTok Bible.',
        },
      ],
    },
    belowAuthors: [
      { text: 'With Articles by ' },
      { text: 'Jonas Moll', link: 'https://www.linkedin.com/in/jonas-moll/' },
      { text: ', ' },
      {
        text: 'Neil Heinisch',
        link: 'https://www.linkedin.com/in/neilheinisch/',
      },
      { text: ', ' },
      { text: 'Onur Mete', link: 'https://www.linkedin.com/in/deronurlive/' },
      { text: ', ' },
      { text: 'Daniel Zoll', link: 'https://www.linkedin.com/in/danielzoll/' },
    ],

    promotion: {
      title: 'Master the TikTok Game!',
      desc:
        'No platform currently offers greater potential for creators and influencers than TikTok. Learn how to create viral content, build reach, conquer a niche and successfully monetize your reach.',
    },

    recipes: {
      title: 'RECIPES FOR MICHELIN STAR TIKTOK GROWTH, BRANDING, MONETIZATION',
      steps: [
        {
          number: '15',
          text: 'ways to come up with unique content ideas',
          marked: '',
        },
        {
          number: '6',
          text: 'high performing hacks on how to outsmart the algorithm',
          marked: '',
        },
        {
          number: '5',
          text: 'featured guest contributions from renowned TikTok experts',
          marked: '',
        },
        {
          number: '4',
          text: 'well tested concepts for monetization',
          marked: '',
        },
      ],
    },

    insideReport: {
      title: 'INSIDE THE REPORT',
      slides: [
        {
          title: 'How TikTok works',
          text:
            'TikTok is different - in every way. From account management to content creation, Adil teaches you which features to use and how to master them.',
        },
        {
          title: 'How you increase your range',
          text:
            "What kind of content goes viral? How do you come up with excellent ideas? What time should you post? Which hashtags work best? Which captions improve your views? We offer the shortcut to success so you can benefit from numerous case studies and learn the do's and don'ts of Tiktok.",
        },
        {
          title: 'How you market yourself',
          text:
            'Reach is one thing – but that doesn’t mean you’ve earned anything yet! In this chapter, you will learn how to build sustainable brands and find the right cooperation partners – or even create your own products.',
        },
      ],
    },
    feedback: {
      title: 'OUR READERS FEEDBACK',
      members: [
        {
          name: 'Herr Anwalt',
          socialLink: 'https://www.tiktok.com/@herranwalt',
          text:
            'This content is priceless - 1000 Euros would still be an appropriate price for the Bible. You go into detail and explain all tricks. You are crazy to give this away for free!',
        },
        {
          name: 'Mareike Rothfuchs',
          socialLink: 'https://www.tiktok.com/@lit_mareike',
          text:
            'Adil bugged me for months to sign up for TikTok. Finally, he convinced me. 30.000 followers and 3 months later I can say: it was worth it!',
        },
        {
          name: 'Jannik Pehlivan',
          socialLink: 'https://www.tiktok.com/@jannikpehlivan',
          text:
            'Using the tips, tricks and hacks from the TikTok Bible, I have gained more followers on TikTok in just 4 months than in 6 years on Instagram.',
        },
      ],
    },

    readySetGrow: {
      title: 'Ready, Set, Grow! ',
    },

    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
    haveRead: 'I have read and accept',
    prepositions: {
      and: 'and',
      the: 'the',
    },
    includeTaxes: '*plus sales taxes',
    isBrandOf: 'is a brand of',
    contactUs: 'Contact Us',
    privacy: 'Privacy Policy',
    imprint: 'Imprint',
    termsConditions: 'Terms and Conditions',
  },
  [avaliableLangs.de]: {
    main: {
      title: 'TikTok Bibel für Influencer & Creator', // :[nexLinePoint]Lerne, wie du auf TikTok Viralität generierst und deine Reichweite monetarisierst',
      desc:
        '200 Seiten Schritt-für-Schritt-Anleitung mit Tipps, Tricks, Beispielen und Hacks für jeden Creator, der Follower und Brand Deals gewinnen oder eigene Produkte vermarkten will. Die ganze Bibel jetzt kostenlos zum Download.',
    },
    buyButton: {
      text: 'JETZT KOSTENLOS HOLEN', //'ERHÄLTLICH AB 3.MÄRZ', //'JETZT KAUFEN FÜR 99€*',
      taxes: 'Preis exkl. USt.',
    },
    orderPromo: {
      text:
        'Jetzt die TikTok Bibel für 89 Euro* vorbestellen (Launch: 12.3.) und 10% Rabatt sichern!',
    },
    freebieButton: {
      text: 'KOSTENLOSEN AUSZUG HERUNTERLADEN',
    },
    workedWith:
      'Bekannte Brands, mit denen unsere Autoren zusammengearbeitet haben',
    // video section is erased for now in tiktok page
    // videoDesc: {
    //   text:
    //     'ERHALTE EINEN ERSTEN KOSTENLOSEN EINDRUCK VOM BIBEL-INHALT IN DIESEM VIDEO',
    //   marked: 'KOSTENLOSEN',
    // },

    authorFirst: {
      title: 'Über den Autor',
      name: 'Adil Sbai',
      desc: [
        {
          text:
            'OnlinePunks CEO Adil hat in den letzten Jahren hunderte Influencer, Unternehmen und Agenturen in Branding- und Marketingfragen beraten, darunter BMW, Falke, Lizza, The Nu Company, Buah oder Heimatgut. OnlinePunks’ Influencer-Suchmaschine influData bietet Unternehmen kostenlose Datenauswertungen von 7 Millionen Content Creatorn und Influencern auf Instagram und TikTok. Zu den Kunden von OnlinePunks und influData gehören Marketer, Agenturen und Brands aus dem gesamten deutschsprachigen Raum. Seit Oktober 2019 arbeitet Adil mit Younes an seinem kometenhaften Aufstieg auf TikTok: Younes’ aktuelle #stayathome-Challenge (Younes stream einen Monat am Stück live auf TikTok) ist aus seiner Feder.',
          links: {
            RoadToGlory: 'https://www.instagram.com/roadtogloryjil/',
            'JulesVogel.': 'https://www.instagram.com/julesvogel/',
          },
        },
      ],
    },
    authorSecond: {
      title: 'Über den Autor',
      name: 'Younes Zarou',
      desc: [
        {
          text:
            'Younes ist einer der kreativsten und am schnellsten wachsenden Influencer Deutschlands. Er ist auf TikTok innerhalb von nur 6 Monaten von 0 auf 3 Mio. Follower gewachsen, während ihm auf Instagram über 50.000 Follower folgen. Er hat mit zahlreichen namhaften Brands kooperiert und gibt seit Januar eigene Tutorials zu Content Creation und Wachstum auf YouTube. In der TikTok-Bibel teilt er erstmals seine Strategie und sein Erfolgsgeheimnis.',
        },
      ],
    },
    belowAuthors: [
      { text: 'Mit Gastbeiträgen von ' },
      { text: 'Jonas Moll', link: 'https://www.linkedin.com/in/jonas-moll/' },
      { text: ', ' },
      {
        text: 'Neil Heinisch',
        link: 'https://www.linkedin.com/in/neilheinisch/',
      },
      { text: ', ' },
      { text: 'Onur Mete', link: 'https://www.linkedin.com/in/deronurlive/' },
      { text: ', ' },
      { text: 'Daniel Zoll', link: 'https://www.linkedin.com/in/danielzoll/' },
    ],

    promotion: {
      title: 'Meistere das TikTok-Game!',
      desc:
        'Keine Plattform bietet aktuell ein größeres Potential für Creator und Influencer als TikTok. Lerne, wie du viralen Content erstellst, Reichweite aufbaust, eine Nische eroberst und deine Reichweite erfolgreich monetarisierst.',
    },

    recipes: {
      title: 'Unsere Tipps für deinen Erfolg als Creator auf TikTok!',
      steps: [
        {
          number: '15',
          text: 'Wege, wie du an einzigartige Content-Ideen kommst',
          marked: '',
        },
        {
          number: '6',
          text: 'nie dagewesene Hacks, wie du den Algorithmus ausspielst',
          marked: '',
        },
        {
          number: '5',
          text: 'Gastbeiträge von anerkannten Experten',
          marked: '',
        },
        {
          number: '4',
          text: 'getestete Konzepte zur Monetarisierung',
          marked: '',
        },
      ],
    },

    insideReport: {
      title: 'EIN BLICK IN DEN REPORT',
      slides: [
        {
          title: 'Wie TikTok funktioniert',
          text:
            'TikToks App ist anders – in jeglicher Hinsicht. Von Account Management bis Content Creation: Adil bringt dir bei, welche Features du wie einsetzen solltest.',
        },
        {
          title: 'Wie du deine Reichweite erhöhst',
          text:
            'Welche Art von Content geht viral? Wie kommst du an gute Ideen? Zu welcher Uhrzeit  solltest du posten? Welche Hashtags funktionieren am besten? Welche Copy verwenden? Nimm die Abkürzung zum Erfolg und profitiere von zahlreichen Fallbeispielen und Do’s und Dont’s.',
        },
        {
          title: 'Wie du dich vermarktest',
          text:
            'Reichweite ist das eine – deshalb hast du aber noch nichts verdient! In diesem Kapitel lernst du, wie du nachhaltig Brands aufbaust und die richtigen Kooperationspartner findest – oder gar eigene Produkte aufbaust.',
        },
      ],
    },
    feedback: {
      title: 'UNSER LESER-FEEDBACK',
      members: [
        {
          name: 'Herr Anwalt',
          socialLink: 'https://www.tiktok.com/@herranwalt',
          text:
            'Der Inhalt ist meines Erachtens unbezahlbar - selbst 1000 Euro wären noch ein fairer Preis! Ihr geht ins Detail und verratet alle Tricks. Ihr seid verrückt, das zu verschenken!',
        },
        {
          name: 'Mareike Rothfuchs',
          socialLink: 'https://www.tiktok.com/@lit_mareike',
          text:
            'Adil nervte mich monatelang, mich endlich auf TikTok anzumelden und irgendwann war ich überzeugt. Die 30.000 Follower und 3 Monaten später kann ich sagen: es lohnte sich!',
        },
        {
          name: 'Jannik Pehlivan',
          socialLink: 'https://www.tiktok.com/@jannikpehlivan',
          text:
            'Mit Hilfe der Tipps, Tricks und Hacks der TikTok Bibel habe ich innerhalb von nur 4 Monaten auf TikTok mehr Follower gewonnen als in 6 Jahren auf Instagram.',
        },
      ],
    },

    readySetGrow: {
      title: 'Dein Eintrittsticket in den TikTok-Himmel',
    },

    privacyPolicy: 'Privacy Policy',
    termsOfUse: 'Terms of Use',
    haveRead: 'I have read and accept',
    prepositions: {
      and: 'and',
      the: 'the',
    },
    includeTaxes: '*zzgl. MwSt.',
    isBrandOf: 'ist eine Marke von',
    contactUs: 'Kontakt',
    privacy: 'Datenschutz',
    imprint: 'Impressum',
    termsConditions: 'AGB',
  },
})

export default tiktokStrings
