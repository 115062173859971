/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled from 'styled-components'
import BuyButton from '@common/buttons/BuyButton'
import avaliableLangs from '@localization/avaliableLangs'
import {
  FormWrapper,
  FormHeaderWrapper,
  FormInputOuterWrapper,
  FormInputWrapper,
  WhoAmIOuterWrapper,
  SelectLangWrapper,
  SelectRoleWrapper,
  SelectLangRadioLabelWrapper,
  SelectRoleRadioLabelWrapper,
  SelectLangRadioWrapper,
  SelectLangGroupWrapper,
  LangSelectHeaderWrapper,
  SignCheckboxOuterWrapper,
  SignCheckboxLabelWrapper,
  SignCheckboxWrapper,
  SignCheckboxTextWrapper,
} from './FormsCommonWrappers'

const ButtonWrapper = styled.div`
  & > button {
    font-size: 19px;
  }
`

const TikTokFullEbookForm = ({
  idForScroll,
  buttonText,
  currentLang,
  isFullWidth,
}) => {
  const formId = idForScroll || 'freeChapterNoscroll'
  const renderGermanTexts = currentLang === avaliableLangs.de
  const renderNewsletter = () => {
    return (
      <>
        <SignCheckboxWrapper
          checkTopPos='-27%'
          id={`field_4Trag${formId}`}
          type='checkbox'
          name='field[4][]'
          value={
            renderGermanTexts
              ? 'Trag dich in unseren Newsletter ein und erhalte ausgewählte Nachrichten von OnlinePunks und unseren Partnern. Deine Informationen verarbeiten wir auf Grundlage unserer Datenschutzregelung und unseren AGB. Du kannst den Newsletter jederzeit abbestellen'
              : 'Subscribe to our newsletter and receive selected news from weCreate and our partners. We process your information on the basis of our Privacy Policy and our Terms and Conditions. You can cancel the newsletter at any time.'
          }
          required
        />
        <SignCheckboxLabelWrapper htmlFor={`field_4Trag${formId}`} />
        <SignCheckboxTextWrapper>
          {renderGermanTexts
            ? `Trag dich in unseren Newsletter ein und erhalte ausgewählte
        Nachrichten von weCreate und unseren Partnern. Deine Informationen
        verarbeiten wir auf Grundlage unserer`
            : `Subscribe to our newsletter and receive selected news from weCreate
        and our partners. We process your information on the basis of our`}{' '}
          <a href={`/${currentLang}/privacy-policy`} target='_blank'>
            {renderGermanTexts ? 'Datenschutzregelung' : 'Privacy Policy'}
          </a>{' '}
          {renderGermanTexts ? 'und' : 'and'}{' '}
          <a href='/termsandconditions' target='_blank'>
            {renderGermanTexts ? 'unseren AGB' : 'Terms and Conditions'}
          </a>
          .{' '}
          {renderGermanTexts
            ? 'Du kannst den Newsletter jederzeit abbestellen.'
            : 'You may opt out at any time.'}
        </SignCheckboxTextWrapper>
      </>
    )
  }

  // ----------------------------------
  return (
    <FormWrapper
      id={formId}
      isFullWidth={isFullWidth}
      style={{ marginLeft: '0px' }}
    >
      <form
        method='POST'
        action='https://onlinepunks.activehosted.com/proc.php'
        id='_form_37_'
        className='_form _form_37 _inline-form  _dark'
        noValidate={false}
      >
        <input type='hidden' name='u' value='37' />
        <input type='hidden' name='f' value='37' />
        <input type='hidden' name='s' />
        <input type='hidden' name='c' value='0' />
        <input type='hidden' name='m' value='0' />
        <input type='hidden' name='act' value='sub' />
        <input type='hidden' name='v' value='2' />
        <div className='_form-content'>
          <div className='_form_element _x53666083 _full_width _clear'>
            <div className='_form-title'>
              {/* Holy Scripture for Performance Marketers in 2020 */}
            </div>
          </div>
          <div className='_form_element _x77596755 _full_width _clear'>
            <div className='_html-code'></div>
          </div>
          <div className='_form_element _x45994927 _full_width _clear'>
            <div className='_html-code'>
              <span>
                {/* 150 Seiten Schritt-für-Schritt-Anleitung mit Tips, Tricks, Beispielen und Hacks für jeden Creator, der Follower und Brand Deals gewinnen oder eigene Produkte vermarkten will */}
              </span>
            </div>
          </div>
          <div className='_form_element _x72983913 _full_width '>
            <label className='_form-label'>{/* Name* */}</label>
            <FormInputOuterWrapper className='_field-wrapper'>
              <FormInputWrapper
                type='text'
                name='firstname'
                placeholder='Name'
                required
              />
            </FormInputOuterWrapper>
          </div>
          <div className='_form_element _x02541768 _full_width '>
            <label className='_form-label'>{/* Email* */}</label>
            <FormInputOuterWrapper className='_field-wrapper'>
              <FormInputWrapper
                type='text'
                name='email'
                placeholder='Email'
                required
              />
            </FormInputOuterWrapper>
          </div>
          {/* --------------------- */}
          <SelectLangGroupWrapper className='_form_element _field14 _full_width '>
            <LangSelectHeaderWrapper className='_form-label'>
              {renderGermanTexts
                ? 'Ich bin ein:'
                : 'I would consider myself as:'}
            </LangSelectHeaderWrapper>
            <WhoAmIOuterWrapper>
              {/* <input
                data-autofill='false'
                type='hidden'
                name='field[9][]'
                value='~|'
              /> */}
              <SelectRoleWrapper className='_row _checkbox-radio'>
                <SelectLangRadioWrapper
                  id={`field_14Influencer or Creator_${formId}`}
                  type='radio'
                  name='field[14]'
                  value='Influencer or Creator'
                  required
                />
                <SelectLangRadioLabelWrapper
                  htmlFor={`field_14Influencer or Creator_${formId}`}
                />
                <SelectRoleRadioLabelWrapper>
                  {renderGermanTexts
                    ? 'Influencer oder Creator'
                    : 'Influencer or Creator'}
                </SelectRoleRadioLabelWrapper>
              </SelectRoleWrapper>

              <SelectRoleWrapper className='_row _checkbox-radio'>
                <SelectLangRadioWrapper
                  id={`field_14Brand or Business Marketer/Owener_${formId}`}
                  type='radio'
                  name='field[14]'
                  value='Brand or Business Marketer/Owener'
                  defaultChecked
                />
                <SelectLangRadioLabelWrapper
                  htmlFor={`field_14Brand or Business Marketer/Owener_${formId}`}
                />
                <SelectRoleRadioLabelWrapper>
                  {renderGermanTexts
                    ? 'Brand- oder Business-Marketer / Eigentümer'
                    : 'Brand or Business Marketer/Owner'}
                </SelectRoleRadioLabelWrapper>
              </SelectRoleWrapper>

              <SelectRoleWrapper className='_row _checkbox-radio'>
                <SelectLangRadioWrapper
                  id={`field_14none of die above_${formId}`}
                  type='radio'
                  name='field[14]'
                  value='none of die above'
                />
                <SelectLangRadioLabelWrapper
                  htmlFor={`field_14none of die above_${formId}`}
                />
                <SelectRoleRadioLabelWrapper>
                  {renderGermanTexts
                    ? 'nichts des oben Genannten'
                    : 'none of the above'}
                </SelectRoleRadioLabelWrapper>
              </SelectRoleWrapper>
            </WhoAmIOuterWrapper>
          </SelectLangGroupWrapper>
          {/* -------------------- */}
          <div className='_form_element _field8 _full_width '>
            <div className='_row'>
              <label className='_form-label'>
                {/* Datenschutz-Bestimmungen*/}
              </label>
            </div>
            <input
              data-autofill='false'
              type='hidden'
              name='field[4][]'
              value='~|'
            />
            <SignCheckboxOuterWrapper className='_row _checkbox-radio'>
              {renderNewsletter()}
            </SignCheckboxOuterWrapper>
          </div>
          <ButtonWrapper className='_button-wrapper _full_width'>
            <BuyButton
              id='_form_37_submit'
              classNameName='_submit'
              type='submit'
              text={buttonText}
              currentLang={currentLang}
            />
          </ButtonWrapper>
          <div className='_clear-element'></div>
        </div>
        <div className='_form-thank-you' style={{ display: 'none' }}></div>
      </form>
    </FormWrapper>
  )
}

TikTokFullEbookForm.propTypes = {
  idForScroll: PropTypes.string,
  buttonText: PropTypes.string,
  currentLang: PropTypes.string,
  isFullWidth: PropTypes.bool,
}

TikTokFullEbookForm.defaultProps = {
  idForScroll: '',
  buttonText: '',
  currentLang: 'en',
  isFullWidth: false,
}

export default TikTokFullEbookForm
