/* eslint-disable */
import React, { useContext } from 'react'
import { ThemeContext } from 'styled-components'
import useLocalizedStrings from '@hooks/useLocalizedStrings'
import BibleAuthor from '@containers/BibleCommonContainers/BibleAuthor'
import BiblePageHeader from '@containers/BibleCommonContainers/BiblePageHeader'
import BiblePageMainInfo from '@containers/BibleCommonContainers/MainInfo/BiblePageMainInfo'
import BibleMainInfoContent from '@containers/BibleCommonContainers/MainInfo/BibleMainInfoContent'
import BiblePageWorkHistory from '@containers/BibleCommonContainers/BiblePageWorkHistory'
import BibleRecipes from '@containers/BibleCommonContainers/BibleRecipes'
import BibleReadySetGrow from '@containers/BibleCommonContainers/BibleReadySetGrow'
import BibleFeedback from '@containers/BibleCommonContainers/BibleFeedback'
import BibleInsideReport from '@containers/BibleCommonContainers/BibleInsideReport'
import BiblePromotion from '@containers/BibleCommonContainers/BiblePromotion'
import BibleReadySetGrowContent from '@containers/BibleCommonContainers/BibleReadySetGrowContent'
import BibleBuyButton from '@containers/BibleCommonContainers/BibleBuyButton'
import BibleSeparateTextSection from '@containers/BibleCommonContainers/BibleSeparateTextSection'
import GetFreeChapterTopButton from '@containers/BibleCommonContainers/GetFreeChapterTopButton'
import TikTokFullEbookForm from '@containers/FreebiesForms/TikTokFullEbookForm'



import withChargebee from '../../hocs/withChargebee'

const TikTokPageContainer = () => {
  const { labelStrings, currentLang } = useLocalizedStrings()
  const theme = useContext(ThemeContext)
  const buyBtnProps = {
    currentLang,
    buttonText: labelStrings.buyButton.text,
    addonsId0: 'tiktok-bible', // presale addon
    // addonsId0: 'tiktok-bible', this addon will be used after presale ends
    // disableAllActions: true, // parameter to disable button totally, can be used for pre-sales
  }
  const idForScroll = 'targetFormForScroll'
  const getFreeChapterBtnProps = {
    currentLang,
    buttonText: labelStrings.buyButton.text,
  }

  const showPromo = currentLang === 'de' // temporary code. will be removed after sales start
  // render page elements
  return (
    <>
      <BiblePageHeader logoSrc='../../MainLogo@2x.png'>
        <GetFreeChapterTopButton
              isHeader
              idForScroll={idForScroll}
              {...getFreeChapterBtnProps}
          />
      </BiblePageHeader>

      <BiblePageMainInfo
        title={labelStrings.main.title}
        posterImgSrc='../../tiktokBible/Hero_DE@1x.png'
        //videoId='5RmALaqPXPo'
        //showVideo
      >
        <BibleMainInfoContent
          text={labelStrings.main.desc}
          withPromo={showPromo}
          //promoText={labelStrings.orderPromo.text}
        >
          <TikTokFullEbookForm
           
            idForScroll={idForScroll}
            {...getFreeChapterBtnProps}
          />
        </BibleMainInfoContent>
      </BiblePageMainInfo>

      <BiblePageWorkHistory
        title={labelStrings.workedWith}
        oneBlockDisplay
        imgSrcs={{
          brands: '../../tiktokBible/Logo_group_brands_01-2.jpg',
          companies:
            '../../tiktokBible/Logo_group_brands_02-2.jpg',
        }}
        // imagesSizes={{ height: 163, width: 630 }}
      />
      <BibleAuthor
        author={labelStrings.authorFirst}
        posterSrc='../../tiktokBible/Adil.png'
        currentLang={currentLang}
        withSocialLink
        socialLink='https://www.linkedin.com/in/adil-sbai-500b75117/'
      />
      <BibleAuthor
        author={labelStrings.authorSecond}
        posterSrc='../../tiktokBible/Youneszarou.png'
        currentLang={currentLang}
        withSocialLink
        socialLink='https://www.linkedin.com/in/younes-zarou-ba04011a2/'
      />
      {/* extra text section after authors */}
      <BibleSeparateTextSection
        belowAuthors={labelStrings.belowAuthors}
        customBG={theme.authorBGColor}
      />

      <BiblePromotion
        promotion={labelStrings.promotion}
        posterSrc='../../tiktokBible/Ipad@1x.png'
      />
      <BibleRecipes recipes={labelStrings.recipes} />
      <BibleFeedback
        title={labelStrings.feedback.title}
        socialSrc='../../social/Tiktok.svg'
        feedbacksMap={[
          {
            src: '../../tiktokBible/Anwalt@1x.png',
            member: labelStrings.feedback.members[0],
          },
          {
            src: '../../tiktokBible/Mareike@1x.png',
            member: labelStrings.feedback.members[1],
          },
          {
            src: '../../tiktokBible/Jannik@1x.png',
            member: labelStrings.feedback.members[2],
          }
        ]}
      />
      <BibleInsideReport
        insideReport={labelStrings.insideReport}
        slides={[
          // positions of slides in array matters, srcs are mapped with corresponding texts
          '../../tiktokBible/Slider_1.png',
          '../../tiktokBible/Slider_2.png',
          '../../tiktokBible/Slider_3.png',
        ]}
      />
      <BibleReadySetGrow
        posterSrc='../../tiktokBible/Footer_@1x.png'
        title={labelStrings.readySetGrow.title}
        currentLang={currentLang}
        twoLineTitle
      >
        <BibleReadySetGrowContent>
        <GetFreeChapterTopButton
              isHeader
              idForScroll={idForScroll}
              {...getFreeChapterBtnProps}
          />
        </BibleReadySetGrowContent>
      </BibleReadySetGrow>
    </>
  )
}

export default withChargebee()(TikTokPageContainer)
